import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BridgeService } from './service/bridge.service';


const resolve = { bridge: BridgeService };

const routes: Routes = [
  { path: 'goods', loadChildren: './goods/goods.module#GoodsModule', resolve },
  { path: 'integral', loadChildren: './integral/integral.module#IntegralModule', resolve  },
  { path: 'follow', loadChildren: './follow-order/follow-order.module#FollowOrderModule', resolve},
  { path: '', component: AppComponent, resolve },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
