import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Error } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {
  uId: string;
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params: { body?: string; url?: string; } = {};
    if (this.uId) {
      if (req.method === 'POST') {
        params.body = req.body + `&uId=${encodeURIComponent(this.uId)}`;
      } else {
        params.url = req.url + (req.url.indexOf('?') > -1 ? '&' : '?') + `uId=${encodeURIComponent(this.uId)}`;
      }
    }
    const authReq = req.clone({
      setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
      ...params
    });

    return next.handle(authReq)
      .pipe(
        switchMap(event => {
          if (event instanceof HttpResponse) {
            const res = formatResponse(event);
            return res.status >= 200 && res.status < 300 ? of<HttpEvent<any>>(res) : throwError(res.body);
          }
          return of(event);
        })
      );
  }
}

function formatResponse(response: HttpResponse<any>) {
  const { status, body }: { status: number; body: { [key: string]: any; error: Error; } } = response;
  if (status >= 200 && status < 300) {
    const { error, ...others } = body;
    let newStatus: number, newBody: any;
    if (error) {
      if (error.errno === 200) {
        const keys = Object.keys(body).filter(k => k !== 'error');
        newStatus = status;
        newBody = keys.length === 0 ? {} : keys.length === 1 ? body[keys[0]] : others;
      } else {
        newStatus = 400;
        newBody = error;
      }
    }
    const res = response.clone({ status: newStatus, body: newBody });
    // console.log(res);
    return res;
  }
  return response;
}

