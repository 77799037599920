export const detail = {
    'XPT': {
        pre: 'pt',
        list: [
            {
                name: '镂空爱心耳钉',
                types: [
                    { type: '7元/份 6g', all: '镂空爱心耳钉 3只', some: '镂空爱心耳钉 1只' },
                    { type: '12元/份 35g', all: '镂空爱心耳钉 17只', some: '镂空爱心耳钉 1只' }
                ],
                params: [
                    { label: '产品材质', value: 'Pt950' },
                    { label: '克重', value: '5g左右' },
                    { label: '订购订金', value: '7元/件、12元/件' }
                ],
                imgs: [
                    'pt1.webp',
                    'pt2.webp',
                ],
                detailIndex: 0,
            },
            {
                name: '在逃公主吊坠',
                types: [
                    { type: '300元/份[Pro]<br>75g', all: '在逃公主吊坠 7条', some: '在逃公主吊坠 1条' },
                    { type: '500元/份 140g', all: '在逃公主吊坠 14条', some: '在逃公主吊坠 1条' },
                ],
                params: [
                    { label: '产品材质', value: 'Pt950,3A白色皓石' },
                    { label: '克重', value: '7g左右' },
                    { label: '订购订金', value: '300元/件、500元/件' }
                ],
                imgs: [
                    'pt11.webp',
                    'pt12.webp',
                    'pt13.webp',
                ],
                detailIndex: 1,
            },
            {
                name: '素圈戒指',
                types: [
                    { type: '105元/份 0.2kg', all: '素圈戒指 2只', some: '银素圈戒指碗 1只' },
                    { type: '175元/份 2kg', all: '素圈戒指 20只', some: '素圈戒指 1只' }
                ],
                params: [
                    { label: '产品材质', value: 'Pt950' },
                    { label: '克重', value: '9g左右' },
                    { label: '订购订金', value: '105元/件、175元/件' }
                ],
                imgs: [
                    'pt111.webp',
                    'pt112.webp',
                    'pt113.webp',
                ],
                detailIndex: 3,
            },
        ]
    },
    'XAG': {
        pre: 'ag',
        list: [
            {
                name: '龙凤呈祥酒杯',
                types: [
                    { type: '105元/份 0.2kg', all: '银碗 2只', some: '银碗 1只' },
                    { type: '175元/份 2kg', all: '银碗 20只', some: '银碗 1只' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '100g/对' },
                    { label: '订购订金', value: '3元/件(0.2kg)、6元/件(0.2kg)' }
                ],
                imgs: [
                    'ag1.webp',
                    'ag2.webp',
                    'ag3.webp',
                ],
                detailIndex: 2,
            },
            {
                name: '龙跃云津茶具套装',
                types: [
                    { type: '48元/份[Pro]<br>4kg', all: '银碗 14套', some: '银碗 1套' },
                    { type: '168元/份 6kg', all: '银碗 21套', some: '银碗 1套' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '全套1352g' },
                    { label: '订购订金', value: '120元/件(8kg)、240元/件(8kg)' }
                ],
                imgs: [
                    'ag11.webp',
                    'ag12.webp',
                    'ag13.webp',
                ],
                detailIndex: 1,
            },
            {
                name: '白福安康茶具套装',
                types: [
                    { type: '48元/份[Pro]<br>4kg', all: '银碗 14套', some: '银碗 1套' },
                    { type: '168元/份 6kg', all: '银碗 21套', some: '银碗 1套' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '全套1575g' },
                    { label: '订购订金', value: '240元/件(16kg)、480元/件(16kg)' }
                ],
                imgs: [
                    'ag111.webp',
                    'ag112.webp',
                    'ag113.webp',
                ],
                detailIndex: 1,
            }
        ]
    },
    'AG': {
        pre: 'ag',
        list: [
            {
                name: '龙凤呈祥酒杯',
                types: [
                    { type: '105元/份 0.2kg', all: '银碗 2只', some: '银碗 1只' },
                    { type: '175元/份 2kg', all: '银碗 20只', some: '银碗 1只' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '100g/对' },
                    { label: '订购订金', value: '3元/件(0.2kg)、6元/件(0.2kg)' }
                ],
                imgs: [
                    'ag1.webp',
                    'ag2.webp',
                    'ag3.webp',
                ],
                detailIndex: 2,
            },
            {
                name: '龙跃云津茶具套装',
                types: [
                    { type: '48元/份[Pro]<br>4kg', all: '银碗 14套', some: '银碗 1套' },
                    { type: '168元/份 6kg', all: '银碗 21套', some: '银碗 1套' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '全套1352g' },
                    { label: '订购订金', value: '120元/件(8kg)、240元/件(8kg)' }
                ],
                imgs: [
                    'ag11.webp',
                    'ag12.webp',
                    'ag13.webp',
                ],
                detailIndex: 1,
            },
            {
                name: '白福安康茶具套装',
                types: [
                    { type: '48元/份[Pro]<br>4kg', all: '银碗 14套', some: '银碗 1套' },
                    { type: '168元/份 6kg', all: '银碗 21套', some: '银碗 1套' }
                ],
                params: [
                    { label: '产品材质', value: '足银' },
                    { label: '克重', value: '全套1575g' },
                    { label: '订购订金', value: '240元/件(16kg)、480元/件(16kg)' }
                ],
                imgs: [
                    'ag111.webp',
                    'ag112.webp',
                    'ag113.webp',
                ],
                detailIndex: 1,
            }
        ]
    },
    'ZSD': {
        pre: 'zn',
        list: [
            {
                name: '锌戒指盒',
                types: [
                    { type: '8元/份 0.04T', all: '戒指盒 134只', some: '戒指盒 1只' }
                ],
                params: [
                    { label: '产品材质', value: '航凯工艺' },
                    { label: '克重', value: '锌合金' },
                    { label: '订购订金', value: '约0.3kg/只' }
                ],
                imgs: [
                    'zn41.jpg',
                    'zn42.jpg',
                    'zn43.jpg',
                ],
                detailIndex: 0,
            },
            {
                name: '酒具套装',
                types: [
                    { type: '65元/份[Pro]<br>1T', all: '酒具套装 458套', some: '酒具套装 1套' }
                ],
                params: [
                    { label: '产品材质', value: '航凯工艺' },
                    { label: '克重', value: '锌合金' },
                    { label: '订购订金', value: '约2.18kg/套' }
                ],
                imgs: [
                    'zn21.jpg',
                    'zn22.jpg',
                    'zn23.jpg',
                ],
                detailIndex: 2,
            },
            {
                name: '复古烛台',
                types: [
                    { type: '72元/份 0.36T', all: '复古烛台 159只', some: '复古烛台 1只' },
                    { type: '288元/份 1.44T', all: '复古烛台 637只', some: '复古烛台 1只' },
                ],
                params: [
                    { label: '产品材质', value: '航凯工艺' },
                    { label: '克重', value: '锌合金' },
                    { label: '订购订金', value: '约2.26kg/只' }
                ],
                imgs: [
                    'zn11.jpg',
                    'zn12.jpg',
                    'zn13.jpg',
                ],
                detailIndex: 1
            },
        ]
    },
    'CAD': {
        pre: 'cu',
        list: [
            {
                name: '悟空摆件',
                types: [
                    { type: '4元/份 0.01T', all: '铜香炉 6只', some: '铜香炉 1只' }
                ],
                params: [
                    { label: '产品材质', value: '黄铜' },
                    { label: '克重', value: '0.3kg' },
                    { label: '订购订金', value: '60元/件(0.3T)、120元/件(0.3T)' }
                ],
                imgs: [
                    'cu1.webp',
                    'cu2.webp',
                    'cu3.webp',
                ],
                detailIndex: 0,
            },
            {
                name: '武圣关公摆件',
                types: [
                    { type: '56元/份[Pro]<br>0.35T', all: '铜龙鼎 78只', some: '铜龙鼎 1只' },
                    { type: '256元/份 0.8T', all: '铜龙鼎 178只', some: '铜龙鼎 1只' },
                ],
                params: [
                    { label: '产品材质', value: '黄铜' },
                    { label: '克重', value: '1.3kg' },
                    { label: '订购订金', value: '240元/件(1.2T)、480元/件(1.2T)' }
                ],
                imgs: [
                    'cu11.webp',
                    'cu12.webp',
                    'cu13.webp',
                ],
                detailIndex: 2,
            },
            {
                name: '手中有钱挂件',
                types: [
                    { type: '64元/份 0.2T', all: '发财树 67棵', some: '发财树 1棵' }
                ],
                params: [
                    { label: '产品材质', value: '黄铜' },
                    { label: '克重', value: '0.3kg' },
                    { label: '订购订金', value: '10元/件(0.05T)、20元/件(0.05T)' }
                ],
                imgs: [
                    'cu111.webp',
                    'cu112.webp',
                    'cu113.webp',
                ],
                detailIndex: 1,
            },
        ]
    },
    'S': {
        pre: 'ni',
        list: [
            {
                name: '优选黄豆',
                types: [
                    { type: '6元/份 0.02T', all: '镍圆盘 4只', some: '镍圆盘 1只' },
                    { type: '3元/份[Pro] 0.12T', all: '镍圆盘 24只', some: '镍圆盘 1只' },
                    { type: '120元/份<br>0.2T', all: '镍圆盘 40只', some: '镍圆盘 1只' },
                    { type: '60元/份[Pro] 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                    { type: '180元/份[Pro] 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                    { type: '360元/份 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                ],
                params: [
                    { label: '供应商', value: '清源禾谷' },
                    { label: '克重', value: '320g*10包' },
                ],
                imgs: [
                    's1.webp',
                    's2.webp',
                    
                ],
                detailIndex: 0,
            }
        ]
    },
    'ZS': {
        pre: 'ni',
        list: [
            {
                name: '优选黄豆',
                types: [
                    { type: '6元/份 0.02T', all: '镍圆盘 4只', some: '镍圆盘 1只' },
                    { type: '3元/份[Pro] 0.12T', all: '镍圆盘 24只', some: '镍圆盘 1只' },
                    { type: '120元/份<br>0.2T', all: '镍圆盘 40只', some: '镍圆盘 1只' },
                    { type: '60元/份[Pro] 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                    { type: '180元/份[Pro] 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                    { type: '360元/份 0.36T', all: '镍圆盘 72只', some: '镍圆盘 1只' },
                ],
                params: [
                    { label: '供应商', value: '清源禾谷' },
                    { label: '克重', value: '320g*10包' },
                ],
                imgs: [
                    's1.webp',
                    's2.webp',
                    
                ],
                detailIndex: 0,
            }
        ]
    },
}