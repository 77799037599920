import { detail } from 'src/environments/detail.penguin';
// export const environment = {
//   production: false,
//   detailData: detail,
//   apiHost: 'https://stone.api.xinxiaohuiwl.com/',
//   socketHost: 'wss://sesame.api.blwhwl.com/',
//   h5Host: 'https://sesame.h5.blwhwl.com/',
//   appKey: 'sesame',
//   appName: '芝麻掘金',
//   appShortName: '芝麻',
//   cnzzAccount: '1275945718',
//   theme: { themeColor: '#5e699f' }
// };
export const environment = {
  production: true,
  detailData: detail,
  apiHost: 'https://toping.api.bj68.xyz/',
  socketHost: 'wss://toping.api.bj68.xyz/',
  h5Host: 'https://toping.h5.bj68.xyz/',
  appKey: 'toping',
  appName: '天品订购',
  appShortName: 'toping',
  cnzzAccount: '1275945718',
  theme: { themeColor: '#5e699f' }
};

