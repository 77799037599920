import { Injectable, NgZone } from '@angular/core';
import { Subject, of, Observable, BehaviorSubject } from 'rxjs';
import { Router, Resolve } from '@angular/router';
import * as bridge from 'dsbridge';
import { publishReplay, refCount, filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BridgeService implements Resolve<boolean> {
  obsEnter: Subject<{ goodsType: string; from: string; flag: string; }> = new Subject();
  obsLeave = new Subject();
  obsSummary: Subject<{ num: number; positionAssets: number; balance: number; }> = new BehaviorSubject({ num: 0, positionAssets: null, balance: 0, });
  obsFullScreen: Subject<{ isHorizontalSuccess: boolean }> = new Subject();
  obsUID: Observable<any>;
  obsGoodsType: Subject<{ goodsType: string }> = new BehaviorSubject({ goodsType: 'PT' }); // 全屏返回竖屏告诉客户端当前选中的是什么品种
  obsRedraw = new Subject();
  messageSubject: Subject<{ action: string;[prop: string]: any }> = new Subject();
  isQAPPMode = false;

  constructor(
    private router: Router,
    private _ngZone: NgZone
  ) {
    this.isQAPPMode = window.navigator.userAgent.indexOf('hap/') > -1 && !!window['system'];
    bridge['register']('callJavaScript', (label, value) => {
      if (value) {
        try {
          value = JSON.parse(value);
        } catch (ex) {
          value = {};
        }
      }
      switch (label) {
        case 'enter': this.obsEnter.next(value); break;
        case 'leave': this.obsLeave.next(); break;
        case 'summary': this.obsSummary.next(value); break;
        case 'fullScreen': this.obsFullScreen.next(value); break;
        case 'redraw': this.obsRedraw.next(); break;
      }
      return label;
    });
    if (this.isQAPPMode) {
      window['system'].onmessage = (msg) => {
        let cmd;
        try {
          cmd = JSON.parse(msg);
        } catch (ex) {
          cmd = {};
        }
        this.messageSubject.next(cmd);
      }
    }

    // 全屏返回竖屏给客户端调用选中的是什么品种
    bridge['register']('getGoodsType', () => {
      let goodsType = {};
      this.obsGoodsType.subscribe((goodsTypeObj) => {
        goodsType = goodsTypeObj;
      });
      return goodsType;
    });

    this.obsEnter.subscribe(({ goodsType, from, flag }) => {
      this._ngZone.run(() => {
        this.obsGoodsType.next({ goodsType });
        this.router.navigate(['/goods'], { queryParams: { goodsType, from, flag, _: +new Date() } });
      }
      );
    });

    this.obsLeave.subscribe(() => {
      this._ngZone.run(() =>
        this.router.navigate(['/'])
      );
    });
    if (this.isQAPPMode) {
      this.obsUID = Observable.create(observer => {
        this.messageSubject.pipe(
          filter(({ action }) => action === 'getUID')
        ).subscribe(({ action, data }) => {
          this._ngZone.run(() =>
            observer.next(data)
          );
        }, err => {
          observer.error(err);
        });
        window['system'].postMessage(JSON.stringify({ action: 'getUID' }));
      }).pipe(publishReplay(1), refCount());
    } else {
      this.obsUID = Observable.create(observer => {
        try {
          let uid = bridge['call']('getUid', { action: 'getUid', url: '' });
          if (!uid) {
            // test
            uid = ''
          }

          observer.next(uid);
        } catch (error) {
          observer.error(error);
        }
      }).pipe(publishReplay(1), refCount());
    }
  }

  resolve(): Observable<boolean> {
    return of(true);
  }

  isTradeOpen() {
    return this.isQAPPMode ? of(true) : Observable.create(observer => {
      try {
        const tradeOpen = bridge['call']('tradeOpen', { action: 'tradeOpen', url: '' });
        const isTradeOpen = tradeOpen === void (0) || tradeOpen === '' || !!tradeOpen;
        observer.next(isTradeOpen);
      } catch (err) {
        observer.next(true);
      }
      observer.complete();
    });
  }

  openOpenPopup(buySell: string, goodsType: string) {
    try {
      bridge['call']('jsApiTodoNative', {
        action: 'createOrder',
        url: '',
        arg: { goodsType, buySell }
      });
    } catch (err) {
      console.error(err);
    }
  }

  openPointOpenPopup(goodsType: string) {
    try {
      bridge['call']('jsApiTodoNative', {
        action: 'createMachOrder',
        url: '',
        arg: { goodsType }
      });
    } catch (err) {
      console.error(err);
    }
  }

  leaveWebview() {
    if (this.isQAPPMode) {
      window['system'].postMessage(JSON.stringify({ action: 'back' }));
    } else {
      try {
        bridge['call']('jsApiTodoNative', {
          action: 'close',
          url: ''
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  // 跳到行情tab页
  jumpTradeTab(goodsType: string) {
    if (this.isQAPPMode) {
      window['system'].go('/Index?tab=1');
    } else {
      try {
        bridge['call']('jsApiTodoNative', {
          action: 'tradeTab',
          url: '',
          arg: { goodsType }
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  // 跳到首页跟买tab
  jumpFollow() {
    if (this.isQAPPMode) {
      window['system'].go('/Index?tab=0');
    } else {
      try {
        bridge['call']('jsApiTodoNative', {
          action: 'home',
          url: '',
        });
      } catch (err) {
        console.error(err);
      }
    }
  }


  // 跳到充值页
  jumpRecharge() {
    if (this.isQAPPMode) {
      window['system'].go('/PackageB/Recharge');
    } else {
      try {
        bridge['call']('jsApiTodoNative', {
          action: 'recharge',
          url: '',
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  // 弹窗行情 - 无交易按钮版
  marketDialog(goodsType: string) {
    try {
      bridge['call']('marketDialog', {
        action: 'marketDialog',
        url: '',
        arg: { goodsType }
      });
    } catch (err) {
      console.error(err);
    }
  }

  // 刷新券列表
  refreshVoucher() {
    if (this.isQAPPMode) {
      window['system'].postMessage(JSON.stringify({ action: 'refreshVoucher' }));
    } else {
      try {
        bridge['call']('jsApiTodoNative', {
          action: 'refreshVoucher',
          url: ''
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  // 跟单
  /**
   * positionId: 订单id
   * goodsId: 品种id
   * goodsType: 商品类型 如：PD
   * buySell: 买涨买跌 0 买涨，1 买跌
   * amount: 购买手数
   * tpPrice: 止盈点数
   * slPrice: 止损点数
   */
  followOrder(positionId: number, goodsId: string, goodsType: string, buySell: number, amount: number, tpPrice: number, slPrice: number) {
    try {
      bridge['call']('jsApiTodoNative', {
        action: 'followOrder',
        url: '',
        arg: {
          positionId,
          goodsId,
          goodsType,
          buySell,
          amount,
          tpPrice,
          slPrice,
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  // 调登录
  login() {
    try {
      bridge['call']('jsApiTodoNative', {
        action: 'login',
        url: ''
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 获取登录态
  getLoginStatus(): string {
    try {
      const loginStatus = bridge['call']('isLogin', { action: 'isLogin', url: '' });
      return loginStatus;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  // 跳到新的webview
  openUrl(url: string, arg: any) {
    try {
      bridge['call']('jsApiTodoNative', {
        action: 'openUrl',
        url: url,
        arg
      });
    } catch (err) {
      console.log(err);
    }
  }

  // 图表横屏
  fullScreen(isHorizontal: boolean) {
    try {
      if (!this.hasNativeMethod('fullScreen')) {
        console.warn('client not define fullScreen method');
        return;
      }

      bridge['call']('fullScreen', { action: 'fullScreen', url: '', arg: { isHorizontal } });
    } catch (err) {
      console.log(err);
    }
  }

  // 判断方法是否存在
  hasNativeMethod(handler: string): boolean {
    try {
      return bridge['hasNativeMethod'](handler);
    } catch (err) {
      console.log(err);
    }
  }

  // 图表加载完毕，通知 native, 隐藏原生的loading
  loadComplete() {
    try {
      bridge['call']('jsApiTodoNative', { action: 'loadComplete', url: '' });
    } catch (err) {
      console.error(err);
    }
  }
}
